.translator {
  width: 80%;
  margin-left: 10%;
}

.row-wrapper {
  display: flex;
}

.translator-container {
  width: 50%;
}

.text-box {
  min-height: 200px;
  width: 100%;
  padding: 10px 15px;
  padding-right: 80px;
  font-size: 18px;
  border-radius: 7px;
}

textarea {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  box-shadow: -2px 5px 10px -5px #000000bf;
  outline: none;
  border: none;
  resize: none;
}

textarea::placeholder,
.output-placeholder {
  color: dimgray;
  user-select: none;
  cursor: text;
}

.input-form {
  position: relative;
}

.icon-btn {
  position: absolute;
  font-size: 20px;
  cursor: pointer;
  margin: 13.5px 20px;
  transition: 0.2s ease-in-out;
  right: 0;
}

.icon-btn:hover {
  color: #696969bf;
}

.speaker {
  font-size: 27px;
  bottom: 2px;
  right: 30px;
}

.copy-btn {
  bottom: 5px;
}

.copy-output-btn {
  bottom: -2px;
}

.output-box {
  position: relative;
  box-shadow: 2px 5px 10px -5px rgba(0, 0, 0, 0.75);
}

.loading-output {
  font-size: 20px;
  font-weight: bold;
  color: #6c757d;
  letter-spacing: 2px;
}


.top-row {
  display: flex;
  align-items: center;
  height: 30px;
  margin: 7px 0px;
}

.btn-translate,
.top-row select {
  margin-left: auto;
  width: 7em;
}

.translation-error {
  color: #ff1234;
}

@media (max-width: 1279px) {
  .translator {
    width: 100%;
    margin-left: 0%;
  }
}

@media (max-width: 768px) {
  .translator-container {
    width: 100%;
  }

  .row-wrapper {
    flex-direction: column;
  }
}